import Container from './components/Container';
import Footer from './components/Footer';
import Head from './components/Head';

function App() {
  return (
    <Container>
      <Head />
      <Footer />
    </Container>
  );
}

export default App;
