import React from 'react';
import style from './container.module.css';

export default function Container({ children }) {
    return (
        <div className={style.container}>
            <div className={style.background}></div>
            {/* <div className={style.content}> */}
                {children}
            {/* </div> */}
        </div>
    )
}