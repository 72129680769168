import React from 'react';
import style from './footer.module.css';
import { BiLogoPlayStore, BiLogoInstagram } from 'react-icons/bi';
import { PiAppStoreLogo } from 'react-icons/pi';

export default function Footer() {
    return (
        <div className={style.footer}>
            <a href='https://www.instagram.com/ironplanapp/' aria-label='Link para acessar Instagram da IRONPLAN' target='_blank'>
                <BiLogoInstagram />
            </a>

            <a href='https://apps.apple.com/br/app/ironplan/id6463141981?l=en-GB' aria-label='Link para baixar o aplicativo IRONPLAN através da Apple Store.' target='_blank'>

                <PiAppStoreLogo />
            </a>

            <a href='https://play.google.com/store/apps/details?id=com.pixelmagic.ironplanapp' aria-label='Link para baixar o aplicativo IRONPLAN através da Google Play.' target='_blank'>
                <BiLogoPlayStore />
            </a>

            <p>Todos os direitos reservados - IRONPLAN 2023</p>
        </div>
    )
}