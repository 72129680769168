import React from 'react';
import style from './head.module.css';
import logo from '../../assets/icon 1.jpg';
import { AiOutlineAndroid, AiOutlineApple } from 'react-icons/ai';

export default function Head() {
    return (
        <div className={style.head}>
            <img src={logo} alt='Logo IRONPLAN' />
            <h1>
                <a href='https://www.instagram.com/ironplanapp/' aria-label='Link para acessar Instagram da IRONPLAN' target='_blank'>
                    @ironplanapp
                </a>
            </h1>
            <h2>SEU NOVO PARCEIRO DE TREINO!</h2>
            <h3>Supere seus limites!</h3>

            <a href='https://ironplan.pixelmagic.com.br' aria-label='Link para a página promocional do aplicativo IRONPLAN.' target='_blank'>
                <div className={style.button}>
                    <img src={logo} alt='Logo IRONPLAN' />
                    <p>CONHECER O APP</p>
                </div>
            </a>

            <h4>BAIXE GRÁTIS!</h4>

            <a href='https://play.google.com/store/apps/details?id=com.pixelmagic.ironplanapp' aria-label='Link para baixar o aplicativo IRONPLAN através da Google Play.' target='_blank'>
                <div className={style.button}>
                    <AiOutlineAndroid />
                    <p>GOOGLE PLAY</p>
                </div>
            </a>

            <a href='https://apps.apple.com/br/app/ironplan/id6463141981?l=en-GB' aria-label='Link para baixar o aplicativo IRONPLAN através da Apple Store.' target='_blank'>
                <div className={style.button}>
                    <AiOutlineApple />
                    <p>APPLE STORE</p>
                </div>
            </a>
        </div>
    )
}